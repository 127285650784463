<template>
  <b-modal id="confirm-patient-reactivation" centered hide-header hide-footer>
    <div class="header">
      <div class="header-content">
        <CheckModal />
        <p>Ativar Paciente</p>
      </div>
    </div>
    <div class="body">
      Você deseja realmente ativar este paciente, isso habilita a
      <strong>opção de edição do prontuário, histórico e documentos.</strong>
      <br />
      Deseja continuar?
    </div>

    <div class="actions">
      <b-button
        class="cancel-button btn-h56"
        variant="outline-danger"
        @click="confirmedReactivation(false)"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        class="btn-h56"
        @click="confirmedReactivation(true)"
      >
        Confirmar
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmPatientReactivation',
  components: {
    CheckModal: () => import('@/assets/icons/check-modal.svg')
  },
  props: {
    confirmedReactivation: Function
  }
}
</script>

<style lang="scss">
#confirm-patient-reactivation {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 640px;
    text-align: center;
    border: none;
    border-radius: 16px;

    .modal-body {
      padding: 0;
      padding-top: 40px;
      .header {
        .header-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 40px svg {
            width: 96px;
            aspect-ratio: 1;
          }

          p {
            margin-top: 32px;
            font-weight: 700;
            font-size: 32px;
            line-height: 126%;
            letter-spacing: -0.01em;
            color: var(--dark-blue);
          }
        }
      }
      .body {
        padding: 16px 80px 0 80px;
        color: var(--type-active);
        font-size: 16px;
        line-height: 150%;
        text-align: center;

        strong {
          font-weight: 700;
        }
      }

      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 48px;
        padding-bottom: 40px;

        .cancel-button {
          font-weight: bold !important;
          border-color: var(--states-red-soft) !important;
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
